<template>
  <div class="card text-center my-0 mx-auto" style="width: 320px">
    <div class="card-header">
      <img :src="logo" class="w-100 px-5 py-2 mb-2" />
      <h5 class="mb-1">Fitnesspoint Members</h5>
    </div>
    <form id="login" class="card-body" v-on:submit.prevent="login" :style="{ opacity: loading ? '0.4' : '1' }">
      <fieldset>
        <div class="mb-2">
          <input id="email" type="email" class="form-control" v-model="email" placeholder="Email" required autofocus />
        </div>
        <div class="mb-2">
          <input id="password" type="password" class="form-control" v-model="password" required placeholder="********" />
        </div>
        <button type="submit" class="btn btn-primary btn-block mb-2">Login</button>
      </fieldset>
      <router-link to="/forgot" class="btn btn-secondary btn-block">Forgot password</router-link>
      <hr />
      <router-link to="/register" class="btn btn-secondary btn-block">Register a new account</router-link>
    </form>
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      error: "",
      logo: require("@/assets/logo.png"),
    };
  },
  methods: {
    async login() {
      if (this.loading) {
        return;
      }
      this.error = "";

      this.loading = true;
      try {
        const { data } = await this.$http.post("/login", {
          email: this.email,
          password: this.password,
        });
        this.$cookies.set("fp-token", data.token);
        this.$http.defaults.headers["Authorization"] = "Bearer " + data.token;
        localStorage.setItem("member", JSON.stringify(data.member));
        localStorage.setItem("messages", JSON.stringify(data.messages));
        if (this.$cookies.get("fp-token") != null) {
          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            this.$router.push("/");
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>
